import styled from 'styled-components'

export const TextareaContainer = styled.div`
  display: block;
  margin-top: 0.2rem;
`

export const StyledTextarea = styled.textarea`
  background-color: #fafafa;
  display: block;
  width: 100%;
  height: 7rem;
  padding: 0.7rem;
  margin-bottom: 0.25rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 2px solid #ddd;
  border-radius: 0.2rem;
  resize: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    /* border: 2px solid props.theme.palette.primary.main; */
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`

export const TextCount = styled.span`
  display: block;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
  text-align: end;
`

export const SubmitContainer = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  text-align: right;
  font-size: 0;
`

export const SwitchContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-top: 1rem;
`

export const SwitchLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`
