import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { analytics } from '../../../../../firebase/firebase'
import { createDiscussion } from '../../../../../redux/thunks/discussions/createDiscussion'
import CreateRebuttalValidation from './validations/create-rebuttal'
// import PostVisibilityForm from '../../../post-form/components/post-visibility-form/post-visibility-form'
// import usePostVisibility from '../../../post-form/hooks/usePostVisibility'
import FormError from '../../../../alerts/form-error'
import EmotionPicker from '../../../emotion-picker/emotion-picker'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, Switch, CircularProgress } from '@mui/material'
import {
  TextareaContainer,
  StyledTextarea,
  TextCount,
  SubmitContainer,
  SwitchContainer,
  SwitchLabel
} from './styled/create-discussion-form-comps'
import VisibilityIndicator from '../visibility-indicator/visibility-indicator'

const useStyles = makeStyles((theme) =>
  createStyles({
    send: {
      marginTop: '1rem',
      marginLeft: '2.5rem',
      verticalAlign: 'middle',
      width: '12rem',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '.3rem auto 0 auto',
      '& svg': {
        color: 'white'
      }
    }
  })
)

const CreateDiscussionForm = (props) => {
  const { take, toggleCreateDiscussionForm, type, courseId } = props
  const classes = useStyles()
  const [rebuttal, setRebuttal] = useState('')
  const [isPrivate, setIsPrivate] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  /** EMOTIONS HOOKS */
  const [topEmotion, setTopEmotion] = useState('')
  const [subEmotion, setSubEmotion] = useState('')
  const [finalEmotion, setFinalEmotion] = useState('')
  /** Post Visibility Hooks */
  // const [visibility, handleVisibilityChange] = usePostVisibility(
  //   type === 'coursePrompt' ? 'course' : 'community'
  // )

  const postEmotion = React.useMemo(() => {
    if (finalEmotion) {
      return finalEmotion
    } else if (subEmotion) {
      return subEmotion
    }
    return topEmotion
  }, [topEmotion, subEmotion, finalEmotion])

  const handleSubmitDiscussionRequest = () => {
    setProcessing(true)

    const { error } = CreateRebuttalValidation({ rebuttal, isPrivate, postEmotion })

    // Check for validation errors and then call action to create take
    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setProcessing(false)
      setError(error)
    } else {
      dispatch(
        createDiscussion({
          takeId: take._id,
          hostUserId: take.userId,
          private: isPrivate,
          rebuttal,
          emotion: postEmotion
        })
      )
        .then((data) => {
          if (data.error) {
            console.log('[FAIL]: ', 'Failed to create discussion request and rebuttal!')

            setError(data.message)
          } else {
            console.log('[SUCCESS]: ', 'Successfully created discussion request and rebuttal!')

            setProcessing(false)
            toggleCreateDiscussionForm(false)

            analytics.logEvent('create_discussion')
          }
        })
        .catch((e) => {
          setProcessing(false)
        })
    }
  }

  const visibilityMessage =
    take?.visibleTo?.key !== 'course'
      ? 'This discussion will be visible to all members of the UConn community. If you prefer not to share your thoughts outside of your course please choose a different post.'
      : 'This discussion will only be visible to other members of your course.'

  return (
    <div>
      <div>
        <VisibilityIndicator visibility={take.visibleTo.key} courseId={take.courseId} />
        {take.courseId && (
          <h3
            style={{
              fontSize: '1rem',
              color: '#000',
              padding: '1rem 0.75rem',
              margin: '0.5rem 0',
              background: '#eaeaea'
            }}>
            {visibilityMessage}
          </h3>
        )}
      </div>
      <FormError message={error} style={{ margin: '1rem 0' }} />

      <TextareaContainer>
        <StyledTextarea
          name="rebuttal"
          value={rebuttal}
          placeholder="Start a 1-on-1 discussion by adding an insight or question for the user."
          wrap="soft"
          maxLength="3000"
          onChange={(e) => setRebuttal(e.target.value)}
        />
        <TextCount>{`${rebuttal.length} / 3000`}</TextCount>
      </TextareaContainer>

      <SubmitContainer>
        <EmotionPicker
          topEmotion={topEmotion}
          subEmotion={subEmotion}
          finalEmotion={finalEmotion}
          setTopEmotion={setTopEmotion}
          setSubEmotion={setSubEmotion}
          setFinalEmotion={setFinalEmotion}
        />
        {/* <PostVisibilityForm
          postFormType={'coursePrompt'}
          visibility={take?.visibility?.key}
          handleVisibilityChange={handleVisibilityChange}
          courseId={courseId}
        /> */}
        <SwitchContainer>
          <Switch
            name="private"
            color="primary"
            checked={isPrivate}
            onChange={() => setIsPrivate(!isPrivate)}
            value={isPrivate}
            style={{ height: '2.5rem' }}
          />
          <SwitchLabel>{isPrivate ? 'As Private' : 'As Public'}</SwitchLabel>
        </SwitchContainer>

        <Button
          variant="contained"
          color="primary"
          className={classes.send}
          onClick={handleSubmitDiscussionRequest}
          disabled={processing || rebuttal.trim().length === 0}>
          {processing ? (
            <CircularProgress color="secondary" className={classes.circularProgress} />
          ) : (
            'Send'
          )}
        </Button>
      </SubmitContainer>
    </div>
  )
}

export default withRouter(CreateDiscussionForm)
