import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import School from '@mui/icons-material/School'
import PublicIcon from '@mui/icons-material/Public'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

function VisibilityIndicator({ visibility, course }) {
  const Badge = () => {
    if (visibility === 'course') {
      return <CourseBadge course={course} />
    } else if (visibility === 'community') {
      return <CommunityBadge />
    } else {
      return <PublicBadge />
    }
  }

  return (
    <MuiBox>
      <Badge />
    </MuiBox>
  )
}

export default VisibilityIndicator

// ***************************** //
// *** Local Components *** //
// ***************************** //
function CourseBadge({ course }) {
  if (!course) return null

  const { subject, courseNumber, title, season, year } = course

  const tooltipTitle = `This post is only visible to members of ${subject} ${courseNumber} - ${title}, ${season} ${year}.`

  return (
    <MuiBox>
      <Typography>Visible only to:</Typography>
      <MuiTooltip title={tooltipTitle}>
        <MuiChip
          icon={<School role="img" />}
          label={`${subject.split('-')[0]} ${courseNumber}`}
          sx={{ marginRight: '0.5rem' }}
          size="small"
        />
      </MuiTooltip>
    </MuiBox>
  )
}

function CommunityBadge() {
  const tooltipTitle = `This post is visible to all members of the UConn Community. If this is a course-related post, it will also be visible to members of your course.  If you wish to make this post private to your course, please click the triple dots on the upper-right corner of this post and select the Edit option from the dropdown menu.`

  return (
    <MuiBox>
      <Typography>Visible to:</Typography>
      <MuiTooltip
        title={
          <>
            <Typography>
              This post is visible to <b>all members of the UConn Community.</b>
            </Typography>
          </>
        }>
        <MuiChip icon={<School role="img" />} label="UConn Community" size="small" />
      </MuiTooltip>
    </MuiBox>
  )
}

function PublicBadge() {
  const tooltipTitle = `This post is visible to all members of the UConn Community. If this is a course-related post, it will also be visible to members of your course.  If you wish to make this post private to your course, please click the triple dots on the upper-right corner of this post and select the Edit option from the dropdown menu.`

  return (
    <MuiBox>
      <Typography>Visible to:</Typography>
      <MuiTooltip
        title={
          <>
            <Typography>
              This post is visible to{' '}
              <b>all members of CivilTalk regardless of school affiliation.</b>
            </Typography>
          </>
        }>
        <MuiChip icon={<PublicIcon role="img" />} label="CivilTalk Community" size="small" />
      </MuiTooltip>
    </MuiBox>
  )
}

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  marginTop: '0.25rem'
}))

const MuiChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light
}))

const MuiSchoolIcon = styled(School)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: '.1rem',
  marginRight: '.4rem',
  fontSize: '1.2rem'
}))

const MuiTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.gray,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
    padding: '1rem'
  }
}))
