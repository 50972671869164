// takes in an array of memberships and returns a boolean indicating if the user is a member of the UConn Community

const uconnOrgId = process.env.NODE_ENV === 'production' ? '65a587332e5f8e0035efe4a4' : '658081a4ce604400356103ba'

function useGetUserIsUConnCommunityMember(memberships) {
  const isUConnCommunityMember = uconnOrgId in memberships

  const uconnCommunityId = isUConnCommunityMember ? uconnOrgId : ''


  return { isUConnCommunityMember, uconnCommunityId } 
}

export default useGetUserIsUConnCommunityMember
