/**
 * determines the visibility value of a post based on the visibility value
 * use it in the post form to determine the visibility value of the take.visibleTo.value
 * returns either courseId, uconnCommunityId, or '' based on the visibility value
 */
type PostVisibility = 'course' | 'community' | 'public'

function useGetVisibilityValue(visibility: PostVisibility, courseId: string, uconnCommunityId: string) {
  if (visibility === 'course') {
    return courseId
  } else if (visibility === 'community') {
    return uconnCommunityId
  } else {
    return ''
  }
}

export default useGetVisibilityValue
