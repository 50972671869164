import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

function EmotionChip({ emotion, colorForEmotion }) {
  const emotionColor = colorForEmotion(emotion)

  return (
    <MuiBox>
      <Typography>feeling - </Typography>
      <MuiChip label={emotion} customColor={emotionColor} />
    </MuiBox>
  )
}

export default EmotionChip

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem'
}))

// Extending the original component's props with a customColor prop
const MuiChip = styled(({ customColor, ...props }) => <Chip {...props} />)(
  ({ theme, customColor }) => ({
    backgroundColor: customColor || theme.palette.secondary.light
  })
)
