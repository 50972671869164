import { useState } from 'react'

// PostVisibility enum may be one of the following values: PUBLIC, COURSE
export type PostVisibility = 'course' | 'community' | 'public'

// initialVisibility for post type of coursePrompt is always 'course'
// initialVisibility for all other post types { question, share } is always 'community'
function usePostVisibility(initialVisibility: PostVisibility) {
  const [visibility, setVisibility] = useState<PostVisibility>(initialVisibility)

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibility(event.target.value as PostVisibility)
  }

  return [visibility, handleVisibilityChange]
}

export default usePostVisibility
