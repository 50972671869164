import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import useThunkDispatch from '../../../../redux/hooks/useThunkDispatch'
import { getCourseByCourseID } from '../../../../redux/thunks/courses/getCourseByCourseID'


interface Course {
  title: string;
  orgId: string;
  instructorId: string;
  desc?: string;
  members: string[];
  subject: string;
  courseNumber: string;
  season: string;
  year: string;
  status: string;
  courseAssistants: string[];
  enrollmentRequests: string[];
}

function useGetCourseDetails(courseId: string) {
  const dispatch = useThunkDispatch()
  const [courseSubject, setCourseSubject] = useState<string>('')
  const [courseNumber, setCourseNumber] = useState<string>('')
  const [courseTitle, setCourseTitle] = useState<string>('')
  const [courseSeason, setCourseSeason] = useState<string>('')
  const [courseYear, setCourseYear] = useState<string>('')
  
  useEffect(() => {
    if (courseId) {
      dispatch(getCourseByCourseID({ courseId }))
        .then((response: any) => {
        if (response.code === 200) {
          setCourseSubject(response.course[0].subject.split(' ')[0])
          setCourseNumber(response.course[0].courseNumber)
          setCourseTitle(response.course[0].title)
          setCourseSeason(response.course[0].season)
          setCourseYear(response.course[0].year)
        } else {
          throw new Error(response)
        }
        }).catch((error: {}) => {
          console.error('[ERROR]: caught in useGetCourseDetails:', error)
        })
    }
  }, [courseId]);

  return { courseSubject, courseNumber, courseTitle, courseSeason, courseYear }
}

export default useGetCourseDetails
