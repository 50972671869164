import { useMemo } from 'react'

const usePlaceholder = (type: string) => {
  const placeholder = useMemo(() => {
    let placeholder
    switch (type) {
      case 'question':
        placeholder = 'Ask the community a question'
        break
      case 'share':
        placeholder = 'Share something with the community'
        break
      case 'coursePrompt':
        placeholder = 'Respond to the prompt'
        break
      default:
        placeholder = 'Share something with the community'
    }

    return placeholder
  }, [type])

  return placeholder
}

export default usePlaceholder
