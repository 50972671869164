import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import { analytics } from '../../../firebase/firebase'
import { getOrganizationsByIDs } from '../../../redux/thunks/organizations/getOrganizationsByIDs'
import { createTake } from '../../../redux/thunks/takes/createTake'
import { createOrganizationTake } from '../../../redux/thunks/takes/createOrganizationTake'
import { postDraftActions } from '../../../redux/action-creators/post-draft'
import CreateTakeValidation from './validations/create-take'
import isVerifiedUser from '../../../utils/functions/isVerifiedUser'
import getOrganizationImage from '../../../utils/functions/getOrganizationImage'
import UserAvatar from '../user-avatar/user-avatar'
// import PromptDisplay from './components/prompt-display/prompt-display'
import PromptDisplay from '../../main/course/components/prompts/components/prompt'
import PostImage from '../post-image/post-image'
import Tags from '../tags/tags'
import TagsSearch from '../tags-search/tags-search'
import RelatedTags from '../related-tags/related-tags'
import EmotionPicker from '../emotion-picker/emotion-picker'
import PostVisibilityForm from './components/post-visibility-form/post-visibility-form'
import VisibilityForm from '../visibility-form/visibility-form'
// Hooks
import usePostVisibility from './hooks/usePostVisibility'
import useGetUserIsUConnCommunityMember from './hooks/useGetUserIsUConnCommunityMember'
import useGetVisibilityValue from './hooks/useGetVisibilityValue'
import usePlaceholder from './hooks/usePlaceholder'
import FormError from '../../alerts/form-error'
import DiscussIcon from '@mui/icons-material/Chat'
import CheckIcon from '@mui/icons-material/Check'
import School from '@mui/icons-material/School'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  ButtonGroup,
  Select,
  ListSubheader,
  MenuItem,
  Button,
  CircularProgress,
  Typography
} from '@mui/material'
import {
  MainContainer,
  Prompt,
  FormContainer,
  FormInputContainer,
  AvatarContainer,
  InputContainer,
  Textarea,
  TextCount,
  TagMessage,
  RelatedTagsHeader,
  EmotionMessage,
  SubmitContainer
} from './styled/post-form-comps'
import { getResponsiveStyles } from './responsive-styles/post-form'
import theme from '../../../res/theme'

import CourseSelect from './components/course-select/course-select'

const styles = {
  visibilityButton: {
    // display: 'inline-block',
    minHeight: '2.2rem',
    // height: '2.2rem',
    color: theme.palette.text.primary,
    backgroundColor: '#ddd',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#eee'
    },
    '& .material-icons': {
      // marginTop: '.2rem',
      fontSize: '1.2rem' // 19px
    }
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    submitButton: {
      //display: 'inline-block',
      minHeight: '2.2rem',
      // height: '2.2rem',
      // fontSize: '0.875rem', // 14px
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '& .material-icons': {
        display: 'inline',
        verticalAlign: 'middle',
        lineHeight: '1rem',
        margin: '.1rem .4rem 0 0',
        fontSize: '1rem'
      },
      '& .submit-text': {
        textTransform: 'capitalize'
      }
    },
    submitText: {
      fontWeight: 700
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '.3rem auto 0 auto',
      '& svg': {
        color: 'white'
      }
    },
    select: {
      width: 0,
      height: 0,
      visibility: 'hidden'
    },
    courseSelect: {},
    selectHeader: {
      padding: '.5rem 1rem',
      lineHeight: '1rem',
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: '1rem' // 16px
    },
    menuItem: {
      minWidth: '10rem',
      padding: '.3rem 1rem',
      color: theme.palette.text.primary,
      fontWeight: 500,
      fontSize: '1rem',
      '.selected': {
        background: 'green' // theme.palette.secondary.main
      }
    },
    discussIcon: {
      marginTop: '.1rem',
      marginRight: '.4rem',
      fontSize: '1.2rem'
    }
  })
)

const PostForm = (props) => {
  const { type, organizationId, setOpen, prompt } = props
  const classes = useStyles()
  const mainRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const [take, setTake] = useState('')
  const [postImage, setPostImage] = useState('')
  const [postImagePreview, setPostImagePreview] = useState('')
  const [tagIds, setTagIds] = useState([])
  const [courseId, setCourseId] = useState('')
  const [selectOpen, setSelectOpen] = useState(false)
  const [discussionLimit, setDiscussionLimit] = useState(10)
  const [discussionLimitSelectOpen, setDiscussionLimitSelectOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isImageDifferent, setIsImageDifferent] = useState(false)
  const [error, setError] = useState('')
  const organizations = useSelector(({ organizations }) => organizations)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  /** POST VISIBILITY HOOKS */
  const [visibility, handleVisibilityChange] = usePostVisibility(
    type === 'coursePrompt' ? 'course' : 'community'
  )
  const { isUConnCommunityMember, uconnCommunityId } = useGetUserIsUConnCommunityMember(
    currentProfile.memberships
  )

  const visibilityValue = useGetVisibilityValue(visibility, courseId, uconnCommunityId)
  const postDraft = useSelector(({ postDraft }) => postDraft)
  const organization = find(organizations, { _id: organizationId }) || {}
  const { avatar, documentVerified, courses } = currentProfile
  const { image } = organization
  const picture = organizationId ? getOrganizationImage(image) : avatar
  const verified = documentVerified ? 1 : 0
  const dispatch = useDispatch()
  const placeholder = usePlaceholder(type)

  /** EMOTIONS HOOKS */
  const [topEmotion, setTopEmotion] = useState('')
  const [subEmotion, setSubEmotion] = useState('')
  const [finalEmotion, setFinalEmotion] = useState('')

  const postEmotion = React.useMemo(() => {
    if (finalEmotion) {
      return finalEmotion
    } else if (subEmotion) {
      return subEmotion
    }
    return topEmotion
  }, [topEmotion, subEmotion, finalEmotion])

  useEffect(() => {
    const textareaEl = document.querySelector('#textarea-post')

    window.addEventListener('resize', handleSetComponentWidth)
    textareaEl.addEventListener('input', autoResize, false)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
      textareaEl.removeEventListener('input', autoResize, false)
    }
  }, [])

  useEffect(() => {
    if (isLoading && postDraft) {
      setTake(postDraft)

      dispatch(getOrganizationsByIDs({ organizationIds: [organizationId] }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully retrieved organizations by IDs!')
          } else {
            console.log('[FAIL]: ', 'Failed to retrieve organizations by IDs.')
          }

          setIsLoading(false)
        })
        .catch((e) => {
          setIsLoading(false)
        })
    }
  }, [dispatch, isLoading, organizationId, postDraft])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  // NICK: check for a props.prompt
  // if true then use the courseId from the prompt
  // else use the first course listed on the profile
  useEffect(() => {
    function defaultCourseState() {
      if (props.prompt) {
        setCourseId(props.prompt.courseId)
      }
      // else if (currentProfile.courses.length) {
      //   // only allow courses where course.status === open
      //   const openCourses = currentProfile.courses.filter((course) => course.status === 'open')
      //   setCourseId(openCourses[0]._id)
      // }
    }

    defaultCourseState()
  }, [])

  const autoResize = () => {
    const textareaEl = document.querySelector('#textarea-post')

    textareaEl.style.height = 'auto'
    textareaEl.style.height = textareaEl.scrollHeight + 'px'
  }

  const handleTakeInputChange = (e) => {
    const { value } = e.target

    if (value.length <= 3000) {
      if (error) setError('')

      setTake(value)
    }

    handleUpdatePostDraft(value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdatePostDraft = useCallback(
    debounce((value) => {
      dispatch(postDraftActions.set(value))
    }, 1000),
    []
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    analytics.logEvent('create_take')

    setProcessing(true)

    const { personalAddress, courses } = currentProfile
    const { city, county, state, zipCode } = personalAddress
    const { error } = CreateTakeValidation({
      take,
      tagIds,
      postEmotion,
      discussionLimit,
      courseId,
      courses
    })

    const actualCourseId = courses.length && courseId === 'no_course' ? '' : courseId

    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setError(error)
      setProcessing(false)
    } else {
      if (organizationId) {
        dispatch(
          createOrganizationTake({
            organizationId: organizationId || '',
            take,
            postImage,
            tagIds,
            emotion: postEmotion,
            discussionLimit,
            isQuestion: false,
            location: { city, county, state, zipCode },
            visibleTo: {
              key: '',
              value: ''
            },
            enableRedux: true,
            currentProfile
          })
        )
          .then((data) => {
            if (!data.error) {
              console.log('[SUCCESS]: ', 'Successfully created organization take!')

              dispatch(postDraftActions.clear())
              setTake('')
              setTagIds([])
              setError('')
              setPostImage('') // clear the image
              setPostImagePreview(null)
              setOpen(false)
              {
                /* confirm these do not break the form */
              }
              setTopEmotion('')
              setSubEmotion('')
              setFinalEmotion('')
              setDiscussionLimit(25)
            } else {
              console.log('[FAIL]: ', 'Failed to create organization take.')

              setError('Something went wrong. Please try again later.')
            }

            setProcessing(false)
          })
          .catch((e) => {
            setProcessing(false)
          })
      } else {
        const takeParams = {
          take,
          postImage,
          tagIds,
          emotion: postEmotion,
          discussionLimit,
          isQuestion: false,
          location: { city, county, state, zipCode },
          visibleTo: {
            key: visibility, // 'course' or 'community' or 'public'
            value: visibilityValue // courseId or communityId or ''
          },
          currentProfile,
          courseId: actualCourseId
        }

        // NICK: if this is a response to a prompt include the promptId else empty string
        if (props.prompt) {
          takeParams.promptId = props.prompt._id
        } else {
          takeParams.promptId = ''
        }

        dispatch(createTake(takeParams))
          .then((data) => {
            if (!data.error) {
              console.log('[SUCCESS]: ', 'Successfully created take!')

              dispatch(postDraftActions.clear())
              // setTake('')
              // setTagIds([])
              // setError('')
              // setPostImage('') // clear the image
              setOpen(false)
            } else {
              console.log('[FAIL]: ', 'Failed to create take.')

              setError('Something went wrong. Please try again later.')
            }

            setProcessing(false)
          })
          .catch((e) => {
            setProcessing(false)
          })
      }
    }
  }

  if (organizationId && Object.keys(organization).length === 0) return null

  return (
    <MainContainer ref={mainRef}>
      {/** NICK: if props.prompt show the prompt component, responseFormView is used to show/hide the "respond" button */}
      {prompt && <PromptDisplay prompt={prompt} responseFormView={true} />}

      {type && (
        <>
          {type === 'share' && (
            <Prompt isOrg={organizationId}>
              <InsertCommentIcon sx={{ color: 'common.white', marginRight: '0.5rem' }} />
              <span>Share your insight</span>
            </Prompt>
          )}
          {type === 'question' && (
            <Prompt>
              <InsertCommentIcon sx={{ color: 'common.white', marginRight: '0.5rem' }} />
              <span>Ask a question</span>
            </Prompt>
          )}
          {type === 'coursePrompt' && (
            <Prompt>
              <InsertCommentIcon sx={{ color: 'common.white', marginRight: '0.5rem' }} />
              <span>Share your response</span>
            </Prompt>
          )}
        </>
      )}

      <FormContainer>
        <FormError message={error} />

        <FormInputContainer>
          <AvatarContainer>
            <UserAvatar
              src={picture}
              width={'2.8rem'}
              height={'2.8rem'}
              borderWidth={'0.2rem'}
              verified={verified} // this will come from the profile
            />
          </AvatarContainer>

          <InputContainer>
            <Textarea
              id="textarea-post"
              name="take"
              value={take}
              maxlength={20}
              placeholder={placeholder}
              onChange={handleTakeInputChange}
            />
          </InputContainer>

          <TextCount>{`${take.length} / 3000`}</TextCount>
        </FormInputContainer>

        <Typography sx={{ fontSize: '1.25rem', padding: '0.5rem 0 0.25rem 0' }}>
          Choose up to 5 topic tags:
        </Typography>

        <TagsSearch
          tagIds={tagIds}
          setTagIds={setTagIds}
          setError={setError}
          variant={'transparent'}
        />

        <Tags tagIds={tagIds} setTagIds={setTagIds} hideTagAdd={true} />

        {tagIds.length < 5 && (
          <RelatedTags
            tagIds={tagIds}
            setTagIds={setTagIds}
            setError={setError}
            limit={7}
            hideReferenceTag={true}
            hideOnNoResults={true}
            enableFullClick={true}
            customHeading={
              <RelatedTagsHeader>
                Here are some existing topics related to{' '}
                <span style={{ whiteSpace: 'nowrap' }}>{tagIds[tagIds.length - 1]}</span>:
              </RelatedTagsHeader>
            }
            style={{ background: 'none', padding: '0', boxShadow: 'none', marginTop: '1rem' }}
          />
        )}

        <Typography sx={{ fontSize: '1.25rem', padding: '0.5rem 0 0.25rem 0' }}>
          Choose an emotion tag:
        </Typography>

        <EmotionPicker
          topEmotion={topEmotion}
          subEmotion={subEmotion}
          finalEmotion={finalEmotion}
          setTopEmotion={setTopEmotion}
          setSubEmotion={setSubEmotion}
          setFinalEmotion={setFinalEmotion}
        />

        <Typography sx={{ fontSize: '1.25rem' }}>Optionally, add an image:</Typography>

        {/** Image Upload Form */}
        <PostImage
          postImage={postImage}
          setPostImage={setPostImage}
          isEditing={false}
          setIsImageDifferent={setIsImageDifferent}
          postImagePreview={postImagePreview}
          setPostImagePreview={setPostImagePreview}
        />

        {/* <PostVisibilityForm
          postFormType={type}
          visibility={visibility}
          handleVisibilityChange={handleVisibilityChange}
          courseId={courseId}
        /> */}

        <VisibilityForm
          postFormType={type}
          visibility={visibility}
          handleVisibilityChange={handleVisibilityChange}
          courseId={courseId}
        />
        <SubmitContainer>
          {/** DISCUSSION LIMIT MENU */}
          <Select
            className={classes.select}
            open={discussionLimitSelectOpen}
            onClose={() => setDiscussionLimitSelectOpen(false)}
            onOpen={() => setDiscussionLimitSelectOpen(true)}
            value={discussionLimit}
            onChange={(e) => setDiscussionLimit(e.target.value)}>
            <ListSubheader className={classes.selectHeader}>Limit Discussion by:</ListSubheader>

            <MenuItem value={10} className={classes.menuItem}>
              Max 10 requests {discussionLimit === 10 && <CheckIcon />}
            </MenuItem>
            <MenuItem value={5} className={classes.menuItem}>
              Max 5 requests {discussionLimit === 5 && <CheckIcon />}
            </MenuItem>
            <MenuItem value={0} className={classes.menuItem}>
              Don't allow any requests {discussionLimit === 0 && <CheckIcon />}
            </MenuItem>
          </Select>

          <ButtonGroup style={{ width: responsiveStyles.buttonGroup.width }}>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: responsiveStyles.visibilityButton.width }}
              disabled={processing}
              onClick={() => setDiscussionLimitSelectOpen(!discussionLimitSelectOpen)}
              sx={styles.visibilityButton}
              aria-label="Discussion Limit Select">
              <DiscussIcon className={classes.discussIcon} />
              {selectOpen ? (
                <span className="material-icons">expand_less</span>
              ) : (
                <span className="material-icons">expand_more</span>
              )}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              style={{ width: responsiveStyles.submitButton.width }}
              disabled={processing || !isVerifiedUser(currentProfile)}
              onClick={handleSubmit}>
              {!isVerifiedUser(currentProfile) && <span className="material-icons">lock</span>}
              {processing ? (
                <CircularProgress color="secondary" className={classes.circularProgress} />
              ) : (
                <span className={classes.submitText}>{type === 'question' ? 'Ask' : 'Share'}</span>
              )}
            </Button>
          </ButtonGroup>
        </SubmitContainer>
      </FormContainer>
    </MainContainer>
  )
}

export default withRouter(PostForm)
